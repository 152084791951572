(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/* ========================================================================
* Galileu JavaScript main engine room
* ====================================================================== */
var A = [{
  from: '(.*)',
  to: '(.*)',
  out: function out(next) {
    gsap.set('#main', {
      autoAlpha: 1
    });
    gsap.to('#main', {
      autoAlpha: 0,
      duration: 0.7,
      ease: 'power3',
      onComplete: next
    });
  },
  "in": function _in(next) {
    gsap.set('window', {
      duration: 0,
      scrollTo: 0
    });
    gsap.set('#main', {
      autoAlpha: 0
    });
    gsap.to('#main', {
      autoAlpha: 1,
      duration: 1,
      ease: 'power3',
      onComplete: next
    });
  }
}];
var R = new Swup({
  containers: ['#main'],
  cache: true,
  plugins: [new SwupJsPlugin(A), new SwupBodyClassPlugin()]
});

window.header = function () {
  return {
    body: document.getElementsByTagName("BODY")[0],
    isVisible: false,
    toggleMainNav: function toggleMainNav() {
      this.isVisible = !this.isVisible;

      if (this.isVisible) {
        this.body.classList.add('in-strains');
      } else {
        this.body.classList.remove('in-strains');
      }

      gsap.fromTo('#mobile-main-menu', this.isVisible ? 0.4 : 0.6, {
        autoAlpha: 1,
        x: this.isVisible ? '100%' : '0%'
      }, {
        x: this.isVisible ? '0%' : '100%',
        ease: 'power2.inOut'
      });
    },
    slideTo: function slideTo(target) {
      var el = document.getElementById(target);

      if (typeof el != 'undefined' && el != null) {
        this.body.classList.remove('in-strains');
        this.isVisible = false;
        gsap.to('#mobile-main-menu', 0.4, {
          autoAlpha: 0,
          x: '100%',
          ease: 'power2.inOut'
        });
        gsap.to(window, 0.8, {
          scrollTo: {
            y: el
          },
          delay: 0.4,
          ease: 'power2.inOut'
        });
      }
    }
  };
};

window.intro = function () {
  return {
    init: function init() {
      var topGraphic = document.getElementById('top-graphic'),
          topAnimation = bodymovin.loadAnimation({
        container: topGraphic,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/anim/intro-graphic-anim.json'
      });
      topAnimation.play();
      window.addEventListener('DOMContentLoaded', function (event) {
        gsap.fromTo('#top .graphic', 0.6, {
          autoAlpha: 0,
          x: 60
        }, {
          autoAlpha: 1,
          x: 0,
          delay: 0.2,
          ease: 'power2.inOut'
        });
        gsap.fromTo('#top .copy', 0.8, {
          autoAlpha: 0,
          y: 60
        }, {
          autoAlpha: 1,
          y: 0,
          delay: 0.6,
          ease: 'power2.inOut'
        });
      });
    }
  };
};

window.services = function () {
  return {
    init: function init() {
      var srGraphic = document.getElementById('study-room-graphic'),
          srAnimation = bodymovin.loadAnimation({
        container: srGraphic,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/anim/study-room-graphic-anim.json'
      });
      srAnimation.play();
      var coachingGraphic = document.getElementById('coaching-graphic'),
          coachingAnimation = bodymovin.loadAnimation({
        container: coachingGraphic,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/anim/coaching-graphic-anim.json'
      });
      coachingAnimation.play();
      var rentalGraphic = document.getElementById('rental-graphic'),
          rentalAnimation = bodymovin.loadAnimation({
        container: rentalGraphic,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/anim/rental-graphic-anim.json'
      });
      rentalAnimation.play();
    }
  };
};

window.inscriptions = function () {
  return {
    form: {
      data: {
        fullname: '',
        email: '',
        contact: '',
        subject: '',
        message: '',
        gprd: '',
        pp_link: window.pp_link,
        csrf_token: window.csrf,
        website: ''
      },
      loading: false,
      submitLabel: 'Enviar',
      feedbackTitle: '',
      feedbackSubtitle: ''
    },
    init: function init() {},
    sendRequest: function sendRequest() {
      var _this = this;

      this.form.submitLabel = 'A enviar…';
      this.form.loading = true;
      this.form.feedbackTitle = '';
      this.form.feedbackSubtitle = '';
      fetch('/request/inscription', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(this.form.data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        console.log(data);
        _this.form.feedbackTitle = data.title;
        _this.form.feedbackSubtitle = data.subtitle;
      })["finally"](function () {
        gsap.fromTo('.form', 0.4, {
          y: 0,
          autoAlpha: 1
        }, {
          y: -40,
          autoAlpha: 0,
          ease: 'power3.inOut'
        });
        gsap.fromTo('.feedback', 0.6, {
          y: 40,
          autoAlpha: 0
        }, {
          y: 0,
          autoAlpha: 1,
          delay: 0.4,
          ease: 'power3.inOut'
        });
        _this.form.data.fullname = '';
        _this.form.data.email = '';
        _this.form.data.contact = '';
        _this.form.data.subject = '';
        _this.form.data.message = '';
        _this.form.data.gprd = '';
        _this.form.loading = false;
        s;
        _this.form.submitLabel = 'Enviar';
        gsap.to('.feedback', 0.6, {
          y: -40,
          autoAlpha: 0,
          ease: 'power3.inOut',
          delay: 6
        });
        gsap.fromTo('.form', 0.4, {
          y: 40,
          autoAlpha: 0
        }, {
          y: 0,
          autoAlpha: 1,
          delay: 6.6,
          ease: 'power3.inOut'
        });
      });
    }
  };
};

window.bottom = function () {
  return {
    tab: 'contact',
    form: {
      data: {
        fullname: '',
        email: '',
        contact: '',
        message: '',
        education: '',
        position: '',
        gprd: '',
        pp_link: window.pp_link,
        csrf_token: window.csrf,
        website: ''
      },
      loading: false,
      submitLabel: 'Enviar',
      feedbackTitle: '',
      feedbackSubtitle: ''
    },
    init: function init() {},
    sendRequest: function sendRequest(fid) {
      var _this2 = this;

      this.form.submitLabel = 'A enviar…';
      this.form.loading = true;
      this.form.feedbackTitle = '';
      this.form.feedbackSubtitle = '';
      fetch(fid === 'application' ? '/request/application' : '/request/contact', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(this.form.data)
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        _this2.form.feedbackTitle = data.title;
        _this2.form.feedbackSubtitle = data.subtitle;
      })["finally"](function () {
        gsap.fromTo('.' + fid + ' .form', 0.4, {
          y: 0,
          autoAlpha: 1
        }, {
          y: -40,
          autoAlpha: 0,
          ease: 'power3.inOut'
        });
        gsap.fromTo('.feedback', 0.6, {
          y: 40,
          autoAlpha: 0
        }, {
          y: 0,
          autoAlpha: 1,
          delay: 0.4,
          ease: 'power3.inOut'
        });
        _this2.form.data.fullname = '';
        _this2.form.data.email = '';
        _this2.form.data.contact = '';
        _this2.form.data.message = '';
        _this2.form.data.education = '';
        _this2.form.data.position = '';
        _this2.form.data.gprd = '';
        _this2.form.submitLabel = 'Enviar';
        gsap.to('.feedback', 0.6, {
          y: -40,
          autoAlpha: 0,
          ease: 'power3.inOut',
          delay: 6
        });
        gsap.fromTo('.' + fid + ' .form', 0.4, {
          y: 40,
          autoAlpha: 0
        }, {
          y: 0,
          autoAlpha: 1,
          delay: 6.6,
          ease: 'power3.inOut'
        });
      });
    },
    scrollTo: function scrollTo(target) {
      var el = document.getElementById(target);

      if (typeof el != 'undefined' && el != null) {
        gsap.to(window, 0.8, {
          scrollTo: {
            y: el,
            offsetY: device.mobile() ? 145 : 0
          },
          ease: 'power2.inOut'
        });
      }
    }
  };
};

window.aside = function () {
  return {
    nav: 'top',
    init: function init() {
      var _this3 = this;

      if (!device.mobile()) {
        var sections = gsap.utils.toArray('.section');
        sections.forEach(function (section, i) {
          ScrollTrigger.create({
            id: section.id,
            trigger: section,
            start: "top center-=10",
            end: "bottom center+=10",
            onToggle: function onToggle(self) {
              return _this3.nav = self.vars.id;
            }
          });
        });
      } // About Animation


      if (!device.mobile()) {
        gsap.fromTo('#about .shape', 0.8, {
          y: 60,
          rotate: 65
        }, {
          scrollTrigger: {
            trigger: '#about',
            start: "top-=25% center",
            end: "top+=50% center",
            scrub: 0.3
          },
          y: -10,
          rotate: -15,
          scale: 1.3,
          ease: 'power2.inOut'
        });
      }

      gsap.fromTo('#about .feature', 0.8, {
        y: 20,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#about',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.15,
        ease: 'power2.inOut'
      }); // Study Room Animation

      gsap.fromTo('#study-room .graphic', 1, {
        x: -40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#study-room',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        x: 0,
        autoAlpha: 1,
        ease: 'power2.out'
      });
      gsap.fromTo('#study-room .copy .to-animate', 0.8, {
        y: 40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#study-room',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.1,
        delay: 0.4,
        ease: 'power2.inOut'
      }); // Coaching Animation

      gsap.fromTo('#coaching .graphic', 1, {
        x: 40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#coaching',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        x: 0,
        autoAlpha: 1,
        ease: 'power2.out'
      });
      gsap.fromTo('#coaching .copy .to-animate', 0.8, {
        y: 40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#coaching',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.1,
        delay: 0.4,
        ease: 'power2.inOut'
      }); // Rental Animation

      gsap.fromTo('#rental .graphic', 1, {
        x: -40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#rental',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        x: 0,
        autoAlpha: 1,
        ease: 'power2.out'
      });
      gsap.fromTo('#rental .copy .to-animate', 0.8, {
        y: 40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#rental',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.1,
        delay: 0.4,
        ease: 'power2.inOut'
      }); // Team Animation

      gsap.fromTo('#team .member', 0.8, {
        y: 20,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#team',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.15,
        ease: 'power2.inOut'
      }); // Inscriptions Animation

      gsap.fromTo('#inscriptions .inscriptions-circle', 1.5, {
        y: -100,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#inscriptions',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        ease: 'elastic.out(1.5, 1)'
      });
      gsap.fromTo('#inscriptions .copy .to-animate', 0.8, {
        x: -40,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#inscriptions',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        x: 0,
        autoAlpha: 1,
        delay: 0.4,
        ease: 'power2.inOut'
      });
      gsap.fromTo('#inscriptions .galileu-form', 0.8, {
        y: 60,
        autoAlpha: 0
      }, {
        scrollTrigger: {
          trigger: '#inscriptions',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        delay: 0.8,
        ease: 'power2.inOut'
      }); // Gallery Animation

      if (!device.mobile()) {
        gsap.fromTo('#gallery .gallery-item', 0.8, {
          autoAlpha: 0,
          y: 60
        }, {
          scrollTrigger: {
            trigger: '#gallery',
            start: "top center",
            end: "bottom center",
            scrub: true
          },
          y: 0,
          autoAlpha: 1,
          stagger: 0.15,
          ease: "expo.out"
        });
      } // Partners Animation


      gsap.fromTo('#partners .partner', 0.8, {
        autoAlpha: 0,
        y: 20
      }, {
        scrollTrigger: {
          trigger: '#partners',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        y: 0,
        autoAlpha: 1,
        stagger: 0.05,
        ease: 'elastic.out(1, 1)'
      }); // Bottom Animation

      if (!device.mobile()) {
        gsap.fromTo('#bottom .scroll', 0.6, {
          autoAlpha: 0,
          y: 60
        }, {
          scrollTrigger: {
            trigger: '#bottom',
            start: "top+=25% center",
            end: "top+=30% center",
            scrub: true
          },
          y: 0,
          autoAlpha: 1,
          ease: 'power2.inOut'
        });
      }

      gsap.fromTo('#bottom .copy', 0.8, {
        autoAlpha: 0,
        x: -40
      }, {
        scrollTrigger: {
          trigger: '#bottom',
          start: "top+=25% center",
          end: "top+=30% center"
        },
        x: 0,
        autoAlpha: 1,
        ease: 'power2.inOut'
      });
      gsap.fromTo('#bottom .galileu-form', 0.8, {
        autoAlpha: 0,
        y: 40
      }, {
        scrollTrigger: {
          trigger: '#bottom',
          start: "top+=20% center",
          end: "top+=35% center"
        },
        y: 0,
        autoAlpha: 1,
        delay: 0.4,
        ease: 'power2.inOut'
      });
      window.addEventListener('DOMContentLoaded', function (event) {
        ScrollTrigger.refresh();
      });
    },
    scrollTo: function scrollTo(target) {
      var el = document.getElementById(target);

      if (typeof el != 'undefined' && el != null) {
        this.nav = target;
        gsap.to(window, 0.8, {
          scrollTo: {
            y: el,
            offsetY: device.mobile() ? 145 : 0
          },
          ease: 'power2.inOut'
        });
      }
    }
  };
};

},{}]},{},[1]);
